import { type PasswordPolicyType } from "@aws-sdk/client-cognito-identity-provider";
import { Text, useTranslator } from "@eo-locale/react";
import {
  json,
  redirect,
  type ActionFunction,
  type LoaderFunction } from
"@remix-run/node";
import {
  Form,
  Link,
  useFetcher,
  useLoaderData,
  useSearchParams,
  useTransition } from
"@remix-run/react";
import { Auth } from "aws-amplify";
import cookieParser from "cookie";
import * as React from "react";
import { type SubmitHandler, useController, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import {
  configureAmplify,
  getCurrentAuthenticatedUser } from
"~/amplify/amplify-utils";
import AmplifyCookieStorage from "~/amplify/cookie-storage";
import WindowSessionStorageMock from "~/amplify/session-storage-mock";
import Layout from "~/components/layout";
import PasswordIcon from "~/components/password-icon";
import Spinner from "~/components/spinner";
import UserFormIcon from "~/components/user-form-icon";
import logger from "~/logger.server";
import {
  type FederatedPool,
  type SSO,
  getSSObyNames,
  getSSObyName } from
"~/models/sso.server";
import { getSession, destroySession, commitSession } from "~/sessions.server";
import basicLoginStyles from "~/styles/basic-login.css";
import styles from "~/styles/onboarding.css";
import {
  clearCookies,
  createHeaders,
  loadConfigurationFromURL,
  mockWindowForAmplify,
  validatePassword } from
"~/utils";

type LoaderData = {
  loginOptions: SSO[];
  redirect: string;
  provider?: string;
  disableAutoSubmit: boolean;
  isLoggedIn?: boolean;
  loginStatus?: "NEW_PASSWORD_REQUIRED" | "MFA_SETUP" | "SOFTWARE_TOKEN_MFA";
  code?: string;
};

const loginStates = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  MFA_SETUP: "MFA_SETUP",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA"
};

export const loader: LoaderFunction = async (args) => {
  const { request } = args;
  const cookies = request.headers.get("Cookie") || "";
  const session = await getSession(cookies);

  const _loader: LoaderFunction = async ({ request }) => {
    const configuration = loadConfigurationFromURL(request);

    const loginOptions = await getSSObyNames(configuration.providers);

    //check if user is already logged in
    if (
    session.get("provider") &&
    session.get("providers")?.includes(session.get("provider")) &&
    configuration.redirect)
    {
      const provider = session.get("provider");

      const amplifySession = session.get("amplify") || {};
      const sessionStorage = new WindowSessionStorageMock(amplifySession);
      const localStorage = new AmplifyCookieStorage(amplifySession);

      const amplifyConfig = loginOptions.find(
        (login) => login.name === provider
      )!;

      async function getCurrentAuthUser() {
        try {
          await configureAmplify({
            ...amplifyConfig.pool.configuration,
            storage: localStorage
          });
          const user = await getCurrentAuthenticatedUser();

          if (configuration.redirect) {
            const configRedirect = configuration.redirect;
            const canRedirect = amplifyConfig.redirects.some((redirect) =>
            redirect.test(configRedirect)
            );

            if (!canRedirect) {
              //TODO: add right err msg
              return redirect("/error");
            }
          }

          if (
          user.challengeName === loginStates.MFA_SETUP ||
          user.attributes?.["custom:mfa"] === "1")
          {
            const code = await Auth.setupTOTP(user);

            return json({
              loginOptions,
              redirect: configuration.redirect,
              provider,
              isLoggedIn: Boolean(user),
              loginStatus: loginStates.MFA_SETUP,
              code
            });
          }

          const { challengeName } = user;
          if (!challengeName) {
            const queryString = new URLSearchParams({
              redirect_uri: (configuration.redirect as string),
              providers: configuration.providers.join()
            }).toString();

            return redirect(`/auth?${queryString}`);
          }

          if (challengeName === loginStates.NEW_PASSWORD_REQUIRED) {
            const newPasswordRequired = loginStates.NEW_PASSWORD_REQUIRED;
            //new password
            session.set("providers", configuration.providers.join());

            return json({
              loginStatus: newPasswordRequired,
              isLoggedIn: Boolean(user),
              loginOptions,
              redirect: configuration.redirect
            });
          }

          if (challengeName === loginStates.SOFTWARE_TOKEN_MFA) {
            //insert otp code
            return json({
              loginStatus: loginStates.SOFTWARE_TOKEN_MFA,
              isLoggedIn: Boolean(user),
              loginOptions,
              redirect: configuration.redirect
            });
          }

          const queryString = new URLSearchParams({
            redirect_uri: (configuration.redirect as string),
            providers: configuration.providers.join()
          }).toString();

          return redirect(`/auth?${queryString}`);
        } catch (error) {
          logger.error(
            { error },
            "Unexpected error while loading current authenticated user"
          );

          const isRevalidatingAfterBasicLogin = session.get("basic");
          if (isRevalidatingAfterBasicLogin !== "true") {
            session.unset("providers");
            return json({
              error,
              loginOptions,
              redirect: configuration.redirect,
              disableAutoSubmit: configuration.disableAutoSubmit
            });
          }

          return json({
            error,
            loginOptions,
            redirect: configuration.redirect,
            disableAutoSubmit: configuration.disableAutoSubmit
          });
        }
      }

      try {
        return mockWindowForAmplify(
          request.url,
          sessionStorage,
          getCurrentAuthUser
        );
      } finally {
        session.set("amplify", amplifySession);
      }
    }

    //YOU'RE NOT LOGGED FOR THE REQUESTED PROVIDERS -> REMOVE COOKIES
    // TODO: destroy session
    Object.keys(session.data).forEach((item) => session.unset(item));
    session.set("providers", configuration.providers);

    return json({
      loginOptions,
      redirect: configuration.redirect,
      disableAutoSubmit: configuration.disableAutoSubmit
    });
  };

  const response = await _loader(args);
  response.headers.set("Set-Cookie", await commitSession(session));
  return response;
};

export const action: ActionFunction = async (args) => {
  const { request } = args;
  const cookies = request.headers.get("Cookie") || "";
  const session = await getSession(cookies);

  const _action: ActionFunction = async ({ request }) => {
    const body = await request.formData();
    const redirectUri = (body.get("redirect") as string);
    const provider = (body.get("provider") as string);

    if (!provider) {
      throw new Error("provider missing");
    }

    const configuration = loadConfigurationFromURL(request);
    const loginOptions = await getSSObyNames(configuration.providers);

    const config = await getSSObyName(provider);

    if (!config) {
      throw new Error("config not found");
    }

    let urlClientShouldredirectTo = "";
    const pool: FederatedPool = (config.pool as FederatedPool);
    pool.configuration.oauth.urlOpener = (url: string, _: string) => {
      urlClientShouldredirectTo = url;
    };

    const amplifySession = session.get("amplify") || {};
    const sessionStorage = new WindowSessionStorageMock(amplifySession);
    const localStorage = new AmplifyCookieStorage(amplifySession);

    async function doSignIn() {
      try {
        await Auth.configure({
          ...pool.configuration,
          storage: localStorage
        });

        await Auth.federatedSignIn({
          customProvider: pool.provider,
          customState: JSON.stringify({
            provider: provider,
            sso: loginOptions.map((_: any) => _.name),
            redirect: redirectUri
          })
        });

        return redirect(urlClientShouldredirectTo);
      } catch (error) {
        logger.error({ error }, "Unexpected error during SSO login");
        return json({ error });
      }
    }

    try {
      return mockWindowForAmplify(request.url, sessionStorage, doSignIn);
    } finally {
      session.set("amplify", amplifySession);
    }
  };

  const response = await _action(args);
  response.headers.set("Set-Cookie", await commitSession(session));
  return response;
};

export function links() {
  return [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: basicLoginStyles }];

}

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const fetcher = useFetcher();
  const transition = useTransition();
  const {
    loginOptions,
    redirect,
    disableAutoSubmit,
    isLoggedIn,
    provider,
    loginStatus,
    code
  } = (useLoaderData() as LoaderData);

  const primaryColor = loginOptions.find(
    (option) => option.branding?.primaryColor !== null
  )?.branding.primaryColor;
  const secondaryColor = loginOptions.find(
    (option) => option.branding?.secondaryColor !== null
  )?.branding.secondaryColor;

  const isSubmitting = transition.state === "submitting";
  const automaticallyRedirect =
  !disableAutoSubmit &&
  loginOptions.length === 1 &&
  loginOptions[0].pool.type === "federated";
  const leftImg = loginOptions.find((option) => option.branding?.logo)?.branding.
  logo;

  React.useEffect(() => {
    const isNotAlreadySubmitting =
    fetcher.state === "idle" && fetcher.type === "init";
    if (automaticallyRedirect && isNotAlreadySubmitting && !disableAutoSubmit) {
      const selectedLogin = loginOptions[0];
      fetcher.submit(
        {
          redirect,
          provider: selectedLogin.name
        },
        { method: "post", action: `/login?index&${searchParams.toString()}` }
      );
    }
  }, [
  automaticallyRedirect,
  fetcher,
  loginOptions,
  redirect,
  disableAutoSubmit,
  searchParams]
  );

  if (automaticallyRedirect) {
    return (
      <Layout color={primaryColor} secondaryColor={secondaryColor}>
        <div
          className="main-content center"
          style={({ "--primary-color": primaryColor } as React.CSSProperties)}>

          <Spinner className="spinner" strokeWidth={4} />
        </div>
      </Layout>);

  }

  const nativeLogin = loginOptions.find(
    (login) => login.pool.type === "native"
  );
  const showNativeLogin = Boolean(nativeLogin);
  const loggedInAndNative =
  loginOptions.find((login) => login.name === provider)?.pool.type ===
  "native";
  const showMFASetup = loginStatus === loginStates.MFA_SETUP;

  return (
    <Layout color={primaryColor} secondaryColor={secondaryColor}>
      <div className="main-container">
        <div className="aside-left">
          <div className="welcome-text">Hello👋 welcome!</div>
        </div>

        {isLoggedIn ?
        showMFASetup ?
        <MFASetup
          username={searchParams.get("username")!}
          code={code!}
          provider={provider!}
          redirect={redirect} /> :

        null :

        <div className="main-right log-in text-center">
            <h1 className="title">Log in</h1>

            <div className="flow">
              {showNativeLogin ?
            <NativeLogin
              isLoggedIn={Boolean(isLoggedIn)}
              provider={nativeLogin!.name}
              passwordPolicy={nativeLogin?.policies?.password}
              redirect={redirect} /> :

            null}

              {loggedInAndNative ? null :
            <div className="logins">
                  {loginOptions.
              filter((login) => login.pool.type === "federated").
              map((login) =>
              <Form key={login.name} method="post">
                        <input
                  style={{ display: "none" }}
                  name="redirect"
                  defaultValue={redirect} />

                        <input
                  style={{ display: "none" }}
                  name="provider"
                  defaultValue={login.name} />


                        <button className="btn sso" type="submit">
                          {login.branding?.icon ?
                  <div className="flex flex-y-center gap-1">
                              <img
                      src={login.branding.icon}
                      alt={login.label}
                      className="sso-icon" />

                              {login.label}
                            </div> :

                  login.label}

                        </button>
                      </Form>
              )}
                </div>}

            </div>
          </div>}

      </div>
    </Layout>);

}

type NativeInputs = {
  otp: string;
  password: string;
  username: string;
  redirect: string;
  provider: string;
};
function NativeLogin({
  isLoggedIn,
  provider,
  redirect,
  passwordPolicy





}: {isLoggedIn: boolean;provider: string;redirect: string;passwordPolicy?: PasswordPolicyType;}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const translator = useTranslator();
  const fetcher = useFetcher();

  const {
    getValues,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NativeInputs>({
    mode: "onBlur",
    defaultValues: {
      redirect,
      provider,
      username: searchParams.get("username") || ""
    }
  });
  const { field } = useController({
    name: "otp",
    control,
    rules: {
      required: false
    }
  });

  const onSubmit: SubmitHandler<NativeInputs> = (data) => {
    if (isLoggedIn) {
      fetcher.submit(
        data.otp ?
        { totp: data.otp, provider: data.provider, redirect: data.redirect } :
        { provider: data.provider, redirect: data.redirect },
        { method: "POST", action: "/login/basic?index" }
      );
    } else {
      fetcher.submit(
        data.otp ?
        {
          totp: data.otp,
          provider: data.provider,
          redirect: data.redirect,
          username: data.username,
          password: data.password
        } :
        {
          provider: data.provider,
          redirect: data.redirect,
          username: data.username,
          password: data.password
        },
        { method: "POST", action: "/login/basic?index" }
      );
    }
  };

  const { data, state } = fetcher;
  const otpError = Boolean(errors.otp);
  const submissionError = fetcher.data?.error;
  const otpInvalid = submissionError?.code === "ExpiredCodeException";
  const isSubmitting = state === "submitting";
  const loginStatus = data?.state;

  const showResetPassword = loginStatus === loginStates.NEW_PASSWORD_REQUIRED;

  if (showResetPassword) {
    const formValues = getValues();
    const username = formValues.username;
    const password = formValues.password;

    return (
      <ResetPassword
        passwordPolicy={passwordPolicy}
        username={username}
        password={password}
        provider={provider}
        redirectUri={redirect} />);


  }

  return (
    <div className="native-login-container flow">
      {isLoggedIn ? null :
      <h2>
          <Text id="log-in-title">
            Inserisci le tue credenziali per accedere
          </Text>
        </h2>}


      <div>
        <div className="form-container no-m-top">
          <form
            className={submissionError ? "flow attention" : "flow"}
            onSubmit={handleSubmit(onSubmit)}>

            <div
              className="flow"
              style={isLoggedIn ? { display: "none" } : undefined}>

              <div className="attention">
                {submissionError ?
                <Text id="log-in-data-not-correct">
                    Le credenziali non sono corrette
                  </Text> :

                ""}

              </div>
              <div className="input-container relative">
                <input
                  id="username"
                  type="text"
                  placeholder={translator.translate("username")}
                  {...register("username", {
                    required: true
                  })} />

                <UserFormIcon className="username-icon" />
              </div>
              <div className="input-container relative">
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: true
                  })} />

                <PasswordIcon className="password-icon" />
              </div>

              <input
                style={{ display: "none" }}
                name="redirect"
                defaultValue={redirect} />

              <input
                style={{ display: "none" }}
                name="provider"
                defaultValue={provider} />


              <div className="reset-password">
                <Link
                  to={`/login/basic/reset-password?provider=${provider}&${searchParams.toString()}`}>

                  <Text id="log-in-forgot">Hai dimenticato la password?</Text>
                </Link>
              </div>
            </div>

            {loginStates.SOFTWARE_TOKEN_MFA === loginStatus ?
            <div className="flow">
                <div className="totp">
                  <label
                  className={
                  submissionError ? "attention subtitle" : "small-subtitle"}>


                    {otpInvalid ?
                  <Text id="log-in-code-error">
                        Inserisci nuovamente il codice
                      </Text> :

                  <Text id="log-in-code-label">
                        Inserisci il codice TOTP generato nella tua app di
                        autenticazione
                      </Text>}

                  </label>
                </div>
                <div
                className={
                submissionError ?
                "attention otp-container" :
                "otp-container"}>


                  <OTPInput
                  {...field}
                  onPaste={(event) => {
                    const data = event.clipboardData.getData("text");
                    if (Number(data)) setValue("otp", data);
                  }}
                  numInputs={6}
                  inputType="number"
                  inputStyle={"otp-input"}
                  renderSeparator={<span className="otp-separator"></span>}
                  renderInput={(props) =>
                  <input {...props} className="otp-input" />}

                  skipDefaultStyles />

                </div>
              </div> :
            null}

            <button type="submit">
              <Text id="log-in">Accedi</Text>
            </button>
          </form>
        </div>
      </div>
    </div>);

}

function MFASetup({
  code,
  username,
  provider,
  redirect





}: {username: string;code: string;provider: string;redirect: string;}) {
  const translator = useTranslator();
  const fetcher = useFetcher();

  const [showCode, setShowCode] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  async function copyCode(event: React.SyntheticEvent) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success(translator.translate("copy-code-success"));
      setShowCode(true);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    if (!otp) {
      return;
    }

    fetcher.submit(
      { totp: otp, provider, redirect },
      { method: "POST", action: "/login/basic/otp-setup" }
    );
  }

  const { data, state } = fetcher;
  const submissionError = fetcher.data?.error;
  const otpInvalid = submissionError?.code === "ExpiredCodeException";
  const isSubmitting = state === "submitting";
  const loginStatus = data?.state;

  return (
    <div className="main-right log-in text-center">
      <h1 className="title">
        <Text id="mfa">Autenticazione multi fattore</Text>
      </h1>

      <div className="flow">
        <div className="mfa-setup flow">
          <div>
            <Text id="mfa-intro">
              Per concludere il processo di Log in è necessario attivare
              l'autenticazione multi fattore. Puoi utilizzare tre modalità
              alternative:
            </Text>
          </div>
          <Text id="mfa-list-intro" html>
            <ul>
              <li>Scopri e scannerizza il «QR CODE» Oppure</li>
              <li>
                clicca su «Rileva il codice» e inseriscilo nella tua app di
                autenticazione
              </li>
            </ul>
            <div>
              Infine, inserisci qui sotto il codice generato dall'app. Se hai
              bisogno di supporto, contatta xxxx@mail.it
            </div>
          </Text>
        </div>

        <div className="flex flex-y-center flex-x-center gap-2">
          <QRCode
            value={`otpauth://totp/Identity:${username}?secret=${code}&issuer=Identity`}
            size={120} />

          {showCode ?
          <div className="mfa-btn active">
              <input type="text" defaultValue={code} readOnly />
            </div> :

          <button className="mfa-btn" onClick={copyCode}>
              <span>
                <Text id="code-reveal">RILEVA IL CODICE</Text>
              </span>
            </button>}

        </div>

        <form className="mfa-submit form-container flow" onSubmit={onSubmit}>
          <div>
            <Text id="code-label">
              Dopo di che, inserisci qui il codice generato
            </Text>
          </div>

          {submissionError ?
          <div className="attention">
              <Text id="code-not-valid">Il codice inserito non è valido</Text>
            </div> :
          null}

          <div
            className={
            submissionError ? "attention otp-container" : "otp-container"}>


            <OTPInput
              value={otp}
              onChange={setOtp}
              onPaste={(event) => {
                const data = event.clipboardData.getData("text");
                if (Number(data)) {
                  setOtp(data);
                }
              }}
              numInputs={6}
              inputType="number"
              inputStyle={"otp-input"}
              renderSeparator={<span className="otp-separator"></span>}
              renderInput={(props) =>
              <input {...props} className="otp-input" />}

              skipDefaultStyles />

          </div>

          <button className="mfa-submit-btn">
            <Text id="confirm">Conferma</Text>
          </button>
        </form>
      </div>
    </div>);

}

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};
const defaultPasswordPolicy: PasswordPolicyType = {
  MinimumLength: 8,
  RequireUppercase: true,
  RequireLowercase: true,
  RequireNumbers: true,
  RequireSymbols: true
};
function ResetPassword({
  passwordPolicy,
  username,
  password,
  provider,
  redirectUri






}: {passwordPolicy?: PasswordPolicyType;username: string;password: string;provider: string;redirectUri: string;}) {
  const fetcher = useFetcher();
  const policy: PasswordPolicyType = {
    ...defaultPasswordPolicy,
    ...(passwordPolicy || {})
  };

  const {
    getValues,
    setValue,
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>({ mode: "onBlur" });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    fetcher.submit(
      {
        newPassword: data.newPassword,
        username,
        password,
        provider,
        redirect: redirectUri
      },
      { action: "/login/basic/change-password", method: "POST" }
    );
  };

  const passwordNotValid = Boolean(errors.newPassword);
  const confirmPasswordNotValid = Boolean(errors.confirmPassword);
  const matchError = errors.confirmPassword?.message === "match-error";
  const submissionError = fetcher.data?.error;

  return (
    <div className="form-container totp">
      <form className="flow" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="subtitle">
            <Text id="new-pswrd-title">Scegli la tua nuova password</Text>
          </label>
        </div>
        {matchError ?
        <div className="attention">
            <Text id="pswrd-not-match">Le password non coincidono</Text>
          </div> :
        null}
        <div
          className={
          passwordNotValid || submissionError ?
          "attention input-container relative" :
          "input-container relative"}>


          <input
            id="password"
            type="password"
            placeholder="Password"
            {...register("newPassword", {
              required: true,
              validate: (password: string) =>
              validatePassword(password, policy)
            })} />

          <PasswordIcon className="password-icon" />
        </div>
        <div
          className={
          confirmPasswordNotValid || submissionError ?
          "attention input-container relative" :
          "input-container relative"}>


          <input
            id="username"
            type="password"
            placeholder="Conferma Password"
            {...register("confirmPassword", {
              required: true,
              validate: (confirmPassoword: string) => {
                if (!validatePassword(confirmPassoword, policy)) {
                  return false;
                }

                const { newPassword } = getValues();
                const isEqualToPassword = newPassword === confirmPassoword;
                if (!isEqualToPassword) {
                  return "match-error";
                }

                return true;
              }
            })} />

          <PasswordIcon className="password-icon" />
        </div>

        <div
          className={
          passwordNotValid || confirmPasswordNotValid ? "attention" : ""}>


          <Text id="password-char" length={policy.MinimumLength}>
            La password deve contenere almeno caratteri
          </Text>
          {policy.RequireNumbers ?
          <Text id="password-numbers">, almeno un numero</Text> :
          null}{" "}
          {policy.RequireUppercase ?
          <Text id="password-uppercase">, una lettera maiuscola</Text> :
          null}
          {policy.RequireSymbols ?
          <Text id="password-special">, un carattere speciale</Text> :
          null}
        </div>

        <button type="submit">
          <Text id="confirm">Conferma</Text>
        </button>
      </form>
    </div>);

}